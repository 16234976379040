<template>
  <div v-bind:class="{
    'programIntroPeriods': true,
    'programIntroPeriods--showAll': showAll,
    'programIntroPeriods--hasFade': hasFade,
    'programIntroPeriods--hideFade': hideFade,
  }">
    <div class="programIntroPeriods__listWrapper">
      <ul class="programIntroPeriods__list" ref="scrollList">
        <li
          v-for="(period, index) in program.periods"
          v-bind:key="index"
          class="programIntroPeriods__item"
        >
          <div class="programIntroPeriods__title">
            {{ period.name }}
          </div>

          <p class="programIntroPeriods__body">
            {{ period.description }}
          </p>
        </li>
      </ul>
    </div>

    <div
      v-if="hasFade && !showAll"
      class="programIntroPeriods__button"
      v-on:click="showAll = true"
    >
      Alle periodes tonen
    </div>
  </div>
</template>

<script>
export default {
  props: {
    program: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showAll: false,
      hasFade: true,
      hideFade: false,
    };
  },

  mounted() {
    const { scrollList } = this.$refs;

    this.hasFade = scrollList.scrollHeight > scrollList.clientHeight;
    scrollList.addEventListener('scroll', this.onScroll);
  },

  methods: {
    onScroll(event) {
      this.hideFade = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.programIntroPeriods__listWrapper {
  position: relative;

  .programIntroPeriods--hasFade & {
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100px;
      background-image: linear-gradient(to bottom, rgba($color-white, 0), $color-white);
      opacity: 1;
      visibility: visible;
      transition: opacity 0.25s, visibility 0.25s;
    }
  }

  @include mobile {
    .programIntroPeriods--showAll & {
      &:after {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  @include desktop {
    &:after {
      .programIntroPeriods--hideFade & {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

.programIntroPeriods__list {
  padding: 0;
  list-style: none;
  overflow-y: hidden;

  @include mobile {
    margin: 0 0 rem(10px) 0;
    max-height: 200px;

    .programIntroPeriods--showAll & {
      margin: 0;
      max-height: none;
    }
  }

  @include desktop {
    margin: 0;
    padding: 0 rem(30px) 0 0;
    max-height: 330px;
    overflow-y: auto;
  }
}

.programIntroPeriods__item {
  display: flex;
  align-items: flex-start;
  margin: 0 0 rem(10px) 0;
}

.programIntroPeriods__title {
  @include label--bold;
  flex: 0 0 80px;
  margin: 0 rem(20px) 0 0;
}

.programIntroPeriods__body {
  @include label;
  flex: 1 1 auto;
}

.programIntroPeriods__button {
  @include label;
  color: $color-green;
  text-decoration: underline;
  text-align: center;

  @include desktop {
    display: none;
  }
}
</style>
<template>
  <base-section class="programIntroGallery">
    <mobile-slider
      wrap-breakpoint="desktop"
      class="programIntroGallery__slider"
    >
      <mobile-slider-item
        v-for="(item, index) in program.gallery"
        v-bind:key="index"
        mobile-width="228px"
        tablet-width="241px"
        desktop-width="calc(100% / 6)"
        gutter="2px"
      >
        <div class="programIntroGallery__itemContainer">
          <img
            v-bind:src="`${item}?w=250&q=80`"
            v-bind:srcset="`${item}?w=250&q=80, ${item}?w=500&q=80 2x`"
            class="programIntroGallery__item"
          />
        </div>
      </mobile-slider-item>
    </mobile-slider>
  </base-section>
</template>

<script>
import BaseSection from '@/components/grid/BaseSection';
import MobileSlider from '@/components/MobileSlider';
import MobileSliderItem from '@/components/MobileSliderItem';

export default {
  components: {
    BaseSection,
    MobileSlider,
    MobileSliderItem,
  },

  props: {
    program: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.programIntroGallery__slider {
  @media(min-width: 1024px) {
    justify-content: center;
  }
}

.programIntroGallery__itemContainer {
  position: relative;
  padding-top: 56.25%;
}

.programIntroGallery__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
<template>
  <base-section class="programIntroOtherPrograms">
    <base-grid modifiers="maxWidth">
      <base-row>
        <base-column>
          <img
            v-bind:src="categoryIllustration"
            class="programIntroOtherPrograms__illustration"
          />

          <div class="programIntroOtherPrograms__title">
            {{ $t('programIntro.otherProgramsTitle') }}
          </div>

          <mobile-slider class="programIntroOtherPrograms__slider">
            <mobile-slider-item
              v-for="(program, index) in programs"
              v-bind:key="index"
              mobile-width="75%"
              desktop-width="33.3%"
            >
              <router-link v-bind:to="{ name: 'programIntro', params: { programId: program.id } }">
                <div class="programIntroOtherPrograms__programCard">
                  <div
                    class="programIntroOtherPrograms__programCardImage"
                    v-bind:style="{ 'background-image': `url(${program.image_vertical}?w=528&q=80)` }"
                  />

                  <div class="programIntroOtherPrograms__programCardContent">
                    <div
                      v-if="program.categories.length"
                      class="programIntroOtherPrograms__programCardCategory"
                    >
                      {{ program.categories[0].name }}
                    </div>

                    <div class="programIntroOtherPrograms__programCardTitle">
                      {{ program.name }}
                    </div>

                    <div
                      v-if="tags"
                      class="programIntroOtherPrograms__programCardTags"
                    >
                      {{ tags }}
                    </div>

                    <base-button
                      modifiers="white small block fullWidth"
                      class="programIntroOtherPrograms__programCardButton"
                    >
                      {{ $t('programs.inactiveProgramButtonLabel') }}
                    </base-button>
                  </div>
                </div>
              </router-link>
            </mobile-slider-item>
          </mobile-slider>
        </base-column>
      </base-row>
    </base-grid>
  </base-section>
</template>

<script>
import BaseSection from '@/components/grid/BaseSection';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import BaseButton from '@/components/BaseButton';
import MobileSlider from '@/components/MobileSlider';
import MobileSliderItem from '@/components/MobileSliderItem';
import getCategoryTheme from '@/utils/getCategoryTheme';

export default {
  components: {
    BaseSection,
    BaseGrid,
    BaseRow,
    BaseColumn,
    BaseButton,
    MobileSlider,
    MobileSliderItem,
  },

  props: {
    program: {
      type: Object,
      required: true,
    },

    programs: {
      type: Array,
      required: true,
    },
  },

  computed: {
    categoryIllustration() {
      const categorySlug = this.program.categories[0]?.slug || 'fit';

      return getCategoryTheme(categorySlug).programIntroCharacter;
    },

    tags() {
      if (this.program.tags.length) {
        return this.program.tags.reduce((acc, { name }, index) => {
          return `${acc}${index !== 0 ? ' · ' : ''}${name}`;
        }, '');
      }

      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.programIntroOtherPrograms {
  position: relative;
  padding: rem(146px) 0 rem(42px) 0;
  background: $color-green repeat url("~@/assets/program/footer-bg-pattern.png");

  @include desktop {
    padding: rem(180px) 0 rem(100px) 0;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 92px;
    background: transparent repeat-x url('~@/assets/program/intro-footer-swirl.svg') center;
  }
}

.programIntroOtherPrograms__illustration {
  position: absolute;
  top: 42px;
  left: calc(50% - 550px);
  transform: translateY(-100%);
}

.programIntroOtherPrograms__title {
  @include heading-4;
  margin: 0 0 rem(32px) 0;
  text-align: center;
  color: $color-white;

  @include desktop {
    @include heading-3;
  }
}

.programIntroOtherPrograms__slider {
  margin: 0 auto;
  max-width: 819px;
}

.programIntroOtherPrograms__programCard {
  position: relative;
  height: 405px;
  border-radius: 10px;
  overflow: hidden;
}

.programIntroOtherPrograms__programCardImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transform: scale(1);
  transition: transform 0.35s;

  .programIntroOtherPrograms__programCard:hover & {
    transform: scale(1.05);
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(#000, 0.8) 97%);
    transition: background 0.35s;

    .programIntroOtherPrograms__programCard:hover & {
      background-color: rgba(#000, 0.5);
    }
  }
}

.programIntroOtherPrograms__programCardContent {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 16px;
  width: 100%;
  background: none;
  transform: translateY(54px);
  transition: transform 0.35s;

  .programIntroOtherPrograms__programCard:hover & {
    transform: translateY(0);
  }
}

.programIntroOtherPrograms__programCardCategory {
  @include tiny--bold;
  margin: 0 0 rem(3px) 0;
  text-transform: uppercase;
  color: $color-white;
}

.programIntroOtherPrograms__programCardTitle {
  @include heading-4;
  margin: 0 0 rem(6px) 0;
  color: $color-white;
}

.programIntroOtherPrograms__programCardTags {
  @include label;
  color: $color-white;
}

.programIntroOtherPrograms__programCardButton {
  margin: rem(16px) 0 0 0;
}
</style>
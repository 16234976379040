<template>
  <base-section
    v-bind:style="cssVars"
    class="programIntroHeader"
  >
    <base-grid modifiers="maxWidth">
      <base-row>
        <base-column>
          <router-link
            v-bind:to="{ name: 'programs' }"
            class="programIntroHeader__backLink"
          >
            <font-awesome-icon
              icon="arrow-left"
              class="programIntroHeader__backLinkIcon"
            />

            Alle programma's
          </router-link>

          <div class="programIntroHeader__mediaWrapper">
            <div
              v-if="!program.video"
              class="programIntroHeader__imageWrapper"
            >
              <img
                v-bind:src="`${program.video_poster_image}?w=675&q=80`"
                v-bind:srcset="`${program.video_poster_image}?w=675&q=80, ${program.video_poster_image}?w=1350&q=80 2x`"
                class="programIntroHeader__image"
              />
            </div>

            <VideoPlayer
              v-if="program.video"
              class="programIntroHeader__video"
              v-bind:src="program.video"
              v-bind:placeholder="`${program.video_poster_image}?w=675&q=80`"
              v-bind:title="program.title"
              v-bind:track-program="program.title"
              v-bind:track-title="program.title"
              track-goal="Program trailer"
            />
          </div>
        </base-column>
      </base-row>
    </base-grid>
  </base-section>
</template>

<script>
import BaseSection from '@/components/grid/BaseSection';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import VideoPlayer from '@/components/VideoPlayer';
import getCategoryTheme from '@/utils/getCategoryTheme';

export default {
  components: {
    BaseSection,
    BaseGrid,
    BaseRow,
    BaseColumn,
    VideoPlayer,
  },

  props: {
    program: {
      type: Object,
      required: true,
    },
  },

  computed: {
    categoryTheme() {
      const categorySlug = this.program.categories[0]?.slug || 'fit';
      const categoryTheme = getCategoryTheme(categorySlug);

      return categoryTheme;
    },

    cssVars() {
      return {
        '--program-detail-bg-image': `url('${this.categoryTheme.programIntroBackgroundImage}')`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.programIntroHeader {
  position: relative;
  padding: rem(16px) 0 0 0 !important;
  background: transparent repeat var(--program-detail-bg-image);
  background-position-x: rem(2500px);
  background-position-y: top;

  @include desktop {
    padding: rem(37px) 0 rem(30px) 0 !important;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: transparent repeat-x url('~@/assets/program/intro-hero-swirl.png');

    @include desktop {
      height: 130px;
    }
  }
}

.programIntroHeader__backLink {
  @include paragraph--semibold;
  display: none;
  position: absolute;
  top: 20px;
  left: 20px;

  @media(min-width: 1080px) {
    display: flex;
    align-items: center;
  }
}

.programIntroHeader__backLinkIcon {
  margin: 0 rem(10px) 0 0;
  font-size: 1.2em;
  opacity: 0.5;
}

.programIntroHeader__mediaWrapper {
  position: relative;
  margin: 0 auto;
  max-width: 675px;
  z-index: 1;
}

.programIntroHeader__imageWrapper {
  padding-top: 56.25%;
}

.programIntroHeader__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
</style>
<template>
  <div class="programIntroDetails">
    <ul class="programIntroDetails__categories">
      <li
        v-for="(category, index) in program.categories"
        v-bind:key="index"
        class="programIntroDetails__category"
      >
        <font-awesome-icon
          icon="plus"
          class="programIntroDetails__categoryIcon"
        />

        {{ category.name }}
      </li>
    </ul>

    <ul class="programIntroDetails__stats">
      <li class="programIntroDetails__stat">
        <font-awesome-icon
          icon="clock"
          class="programIntroDetails__statIcon"
        />

        <span class="programIntroDetails__statLabel">
          {{ program.workout_duration }} min. workout
        </span>
      </li>

      <li class="programIntroDetails__stat">
        <font-awesome-icon
          icon="calendar"
          class="programIntroDetails__statIcon"
        />

        <span class="programIntroDetails__statLabel">
          {{ program.number_workouts }} workouts
        </span>
      </li>

      <li class="programIntroDetails__stat">
        <font-awesome-icon
          icon="grid"
          class="programIntroDetails__statIcon"
        />

        <span class="programIntroDetails__statLabel">
          {{ program.periods.length }} periodes
        </span>
      </li>

      <li class="programIntroDetails__stat">
        <font-awesome-icon
          icon="signal"
          class="programIntroDetails__statIcon"
        />

        <span class="programIntroDetails__statLabel">
          {{ program.number_intensities }} niveau
        </span>
      </li>

      <li class="programIntroDetails__stat">
        <font-awesome-icon
          icon="utensils"
          class="programIntroDetails__statIcon"
        />

        <span class="programIntroDetails__statLabel">
          {{ program.number_recipes }} recepten
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    program: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.programIntroDetails {
  display: flex;
  padding: rem(20px) rem(16px);
  border-radius: 8px;
  background: $color-beige;
}

.programIntroDetails__categories {
  flex: 0 0 50%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.programIntroDetails__category {
  @include paragraph--semibold;
  display: flex;
  align-items: center;
  margin: 0 0 rem(15px) 0;

  &:last-child {
    margin: 0;
  }
}

.programIntroDetails__categoryIcon {
  margin: 0 rem(7px) 0 0;
  color: $color-pink--dark;
}

.programIntroDetails__stats {
  flex: 0 0 50%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.programIntroDetails__stat {
  display: flex;
  align-items: center;
  margin: 0 0 rem(10px) 0;

  &:last-child {
    margin: 0;
  }
}

.programIntroDetails__statIcon {
  margin: 0 rem(8px) 0 0;
  color: $color-beige--dark;
}

.programIntroDetails__statLabel {
  @include label;
  color: rgba($color-text, 0.7);
}
</style>
<template>
  <div
    v-bind:class="{
      'mobileSliderItem': true,
      'mobileSliderItem--hasTabletWidth': !!tabletWidth,
    }"
    v-bind:style="{
      '--mobileWidth': mobileWidth,
      '--tabletWidth': tabletWidth,
      '--desktopWidth': desktopWidth,
      '--gutter': gutter,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    mobileWidth: {
      type: String,
      required: true,
    },

    tabletWidth: {
      type: String,
      default: null,
    },

    desktopWidth: {
      type: String,
      required: true,
    },

    gutter: {
      type: String,
      default: '0.5rem',
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.mobileSliderItem {
  flex: 0 0 auto;
  padding: 0 var(--gutter);
  width: var(--mobileWidth);

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  @include desktop {
    width: var(--desktopWidth);
  }

  &--hasTabletWidth {
    @media(min-width: 768px) {
      width: var(--tabletWidth);
    }
  }

  @media(min-width: 1024px) {
    width: var(--desktopWidth);
  }
}
</style>
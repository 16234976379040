<template>
  <div
    v-bind:class="{
      [blockName]: true,
      [modifierClasses]: true,
    }"
  >
    <div class="programIntroCta__title">
      {{
        $t('programIntro.programAndTrainerLabel', {
          program: program.name,
          trainer: program.trainer,
        })
      }}
    </div>

    <p
      v-if="program.slogan"
      class="programIntroCta__body"
    >
      {{ program.slogan }}
    </p>

    <base-button
      modifiers="primary"
      v-on:click="$emit('startProgram')"
      class="programIntroCta__button"
    >
      {{ buttonLabel }}
    </base-button>
  </div>
</template>

<script>
import modifiers from '@/mixins/modifiers';
import BaseButton from '@/components/BaseButton';

export default {
  mixins: [
    modifiers(['leftAlign']),
  ],

  components: {
    BaseButton,
  },

  props: {
    program: {
      type: Object,
      required: true,
    },

    buttonLabel: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      blockName: 'programIntroCta',
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.programIntroCta {
  text-align: center;

  @include desktop {
    &--leftAlign {
      text-align: left;
    }
  }
}

.programIntroCta__title {
  @include heading-3;
  margin: 0 0 rem(10px) 0;

  @include desktop {
    @include heading-2;
  }
}

.programIntroCta__body {
  margin: 0 0 rem(30px) 0;
}
</style>
<template>
  <div v-bind:class="{
    'mobileSlider': true,
    [`mobileSlider--${wrapBreakpoint}`]: true,
  }">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    wrapBreakpoint: {
      type: String,
      default: 'tablet',
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.mobileSlider {
  display: flex;
  flex-wrap: nowrap;
  margin-left: -$page-padding-sides;
  padding: 0 $page-padding-sides;
  width: calc(100% + (#{$page-padding-sides} * 2));
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  &--tablet {
    @include desktop {
      flex-wrap: wrap;
      margin-left: 0;
      padding: 0;
      width: auto;
    }
  }

  &--desktop {
    @media(min-width: 1024px) {
      flex-wrap: wrap;
      margin-left: 0;
      padding: 0;
      width: auto;
    }
  }
}
</style>

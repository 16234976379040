<template>
  <ul class="programIntroTrainers">
    <li
      v-for="(specialist, index) in program.specialists"
      v-bind:key="index"
      class="programIntroTrainers__item"
    >
      <img
        v-bind:src="`${specialist.photo}?w=105`"
        v-bind:alt="specialist.name"
        class="programIntroTrainers__image"
      />

      <div class="programIntroTrainers__content">
        <div class="programIntroTrainers__name">
          {{ specialist.name }}
        </div>

        <p class="programIntroTrainers__body">
          {{ specialist.description }}
        </p>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    program: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.programIntroTrainers {
  margin: 0;
  padding: 0;
  list-style: none;
}

.programIntroTrainers__item {
  display: flex;
  align-items: flex-start;
  margin: 0 0 rem(24px) 0;

  &:last-child {
    margin: 0;
  }

  @include desktop {
    display: block;
    margin: 0 0 rem(40px) 0;
  }
}

.programIntroTrainers__image {
  flex: 0 0 auto;
  margin: 0 rem(12px) 0 0;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  object-fit: cover;

  @include desktop {
    margin: 0 0 rem(16px) 0;
    width: 105px;
    height: 105px;
  }
}

.programIntroTrainers__content {
  flex: 1 1 auto;
}

.programIntroTrainers__name {
  @include paragraph--bold;

  @include desktop {
    @include lead--bold;
    margin: 0 0 rem(4px) 0;
  }
}

.programIntroTrainers__body {
  @include note;

  @include desktop {
    @include label;
    max-width: 260px;
  }
}
</style>
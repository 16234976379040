<template>
  <div
    v-if="!isLoading"
    class="programIntro"
  >
    <program-intro-header v-bind:program="currentProgram" />

    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column modifiers="sm-6 md-5">
            <program-intro-cta
              modifiers="leftAlign"
              v-bind:program="currentProgram"
              v-bind:button-label="buttonLabel"
              v-on:startProgram="onStartProgramClick"
            />

            <program-intro-details
              v-bind:program="currentProgram"
              class="programIntro__details"
            />

            <program-intro-trainers
              v-bind:program="currentProgram"
              class="programIntro__trainers programIntro__trainers--desktop"
            />
          </base-column>

          <base-column modifiers="sm-6 md-offset-1">
            <div
              v-if="currentProgram.summary"
              v-html-safe="currentProgram.summary"
              class="programIntro__summary"
            />

            <div
              v-html-safe="currentProgram.description"
              class="programIntro__description"
            />

            <ul
              v-if="currentProgram.tags.length"
              class="programIntro__tags"
            >
              <li
                v-for="(tag, index) in currentProgram.tags"
                v-bind:key="index"
                class="programIntro__tag"
              >
                {{ tag.name }}
              </li>
            </ul>

            <program-intro-periods
              v-bind:program="currentProgram"
              class="programIntro__periods"
            />

            <div class="programIntro__ctaBlock">
              <div class="programIntro__ctaBlockTitle">
                {{
                  $t('programIntro.programAndTrainerLabel', {
                    program: currentProgram.name,
                    trainer: currentProgram.trainer,
                  })
                }}
              </div>

              <base-button
                modifiers="white"
                v-on:click="onStartProgramClick"
                class="programIntro__ctaBlockButton"
              >
                {{ buttonLabel }}
              </base-button>

              <img
                v-bind:src="ctaBlockDoodle"
                class="programIntro__ctaBlockDoodle"
              />
            </div>

            <program-intro-trainers
              v-bind:program="currentProgram"
              class="programIntro__trainers programIntro__trainers--mobile"
            />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <program-intro-gallery
      v-if="currentProgram.gallery.length"
      v-bind:program="currentProgram"
      class="programIntro__gallery"
    />

    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <program-intro-cta
              v-bind:program="currentProgram"
              v-bind:button-label="buttonLabel"
              v-on:startProgram="onStartProgramClick"
            />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <program-intro-other-programs
      v-if="inactivePrograms.length >= 3"
      v-bind:program="currentProgram"
      v-bind:programs="limitedOtherPrograms"
    />
  </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import { mapGetters } from 'vuex';
import BaseSection from '@/components/grid/BaseSection';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import BaseButton from '@/components/BaseButton';
import ProgramIntroHeader from '@/components/program/ProgramIntroHeader';
import ProgramIntroCta from '@/components/program/ProgramIntroCta';
import ProgramIntroDetails from '@/components/program/ProgramIntroDetails';
import ProgramIntroPeriods from '@/components/program/ProgramIntroPeriods';
import ProgramIntroTrainers from '@/components/program/ProgramIntroTrainers';
import ProgramIntroGallery from '@/components/program/ProgramIntroGallery';
import ProgramIntroOtherPrograms from '@/components/program/ProgramIntroOtherPrograms';
import getCategoryTheme from '@/utils/getCategoryTheme';
import { ModalBus } from '@/eventBus';

const StartProgramModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/program/StartProgramModal' /* webpackChunkName: "startProgramModal" */),
}));

export default {
  components: {
    BaseSection,
    BaseGrid,
    BaseRow,
    BaseColumn,
    BaseButton,
    ProgramIntroHeader,
    ProgramIntroCta,
    ProgramIntroDetails,
    ProgramIntroPeriods,
    ProgramIntroTrainers,
    ProgramIntroGallery,
    ProgramIntroOtherPrograms,
  },

  data() {
    return {
      isLoading: true,
    };
  },

  computed: {
    ...mapGetters('program', ['currentProgram', 'inactivePrograms']),

    limitedOtherPrograms() {
      const filtered = this.inactivePrograms.filter(({ id }) => id !== this.currentProgram.id);
      const shuffled = filtered.sort(() => 0.5 - Math.random());

      return shuffled.slice(0, 3);
    },

    buttonLabel() {
      if (this.currentProgram.active_period) {
        return this.$t('programIntro.activeProgramButtonLabel');
      }

      return this.$t('programIntro.inactiveProgramButtonLabel');
    },

    ctaBlockDoodle() {
      const categorySlug = this.currentProgram.categories[0]?.slug || 'fit';

      return getCategoryTheme(categorySlug).programIntroDoodle;
    },
  },

  watch: {
    '$route.params.programId'() {
      this.fetchData();
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      const { programId } = this.$route.params;

      this.isLoading = true;
      await this.$store.dispatch('program/fetch', programId);
      this.isLoading = false;
    },

    onStartProgramClick() {
      if (this.currentProgram.active_period) {
        this.$router.push({
          name: 'programWorkout',
          params: {
            programId: this.currentProgram.id,
            periodId: this.currentProgram.active_period,
          },
        });

        return;
      }

      ModalBus.emit('open', {
        component: StartProgramModal,
        props: { programId: this.currentProgram.id },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/lib";

.programIntro__summary {
  &,
  & p {
    @include lead;
    margin: 0 0 rem(30px) 0;

    @include desktop {
      @include title;
    }
  }
}

.programIntro__description {
  &,
  & p {
    @include small;
    margin: 0 0 rem(50px) 0;

    @include desktop {
      @include paragraph;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/scss/lib";

.programIntro__trainers {
  &--mobile {
    @include desktop {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include desktop {
      display: block;
    }
  }
}

.programIntro__details {
  margin: rem(30px) 0 0 0;

  @include desktop {
    margin: rem(30px) 0 rem(60px) 0;
  }
}

.programIntro__periods {
  margin: 0 0 rem(50px) 0;

  @include desktop {
    margin: 0 0 rem(34px) 0;
  }
}

.programIntro__tags {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 rem(50px) 0;
  padding: 0;
  list-style: none;
}

.programIntro__tag {
  @include label;
  margin: 0 rem(8px) rem(8px) 0;
  padding: 8px 13px;
  border-radius: 6px;
  background: $color-beige;
  color: rgba($color-text, 0.7);
}

.programIntro__ctaBlock {
  display: none;
  position: relative;
  padding: rem(24px);
  border-radius: 10px;
  background: $color-green;

  @include desktop {
    display: block;
  }
}

.programIntro__ctaBlockTitle {
  @include heading-3;
  margin: 0 0 rem(24px) 0;
  color: $color-white;
}

.programIntro__ctaBlockDoodle {
  position: absolute;
  right: 30px;
  bottom: -24px;
}

.programIntro__gallery {
  margin: 18px 0 28px 0;

  @include desktop {
    margin: 83px 0 38px 0;
  }
}
</style>